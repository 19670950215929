
import React, { useRef } from 'react'
import Slider from 'react-slick';
import Project from './Project';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';
let data = [
    {
        img : "social-gaming.gif",
        disc : "Social Gaming is a basic game engine that enables sandbox " +
            "styled game settings to be created as a way for users to create " +
            "creative party games.",
        demo:"https://gitlab.edwardchu.ca/echu/social-gaming"
    },
    {
        img : "inventory.gif",
        disc : "An Inventory System created in Java with Spring Boot to handle " +
            "server requests and storage of inventory data entered by the client",
        demo:"https://gitlab.edwardchu.ca/echu/inventory-server",
        demo2:"https://gitlab.edwardchu.ca/echu/inventory-client"
    },
    {
        img : "social-gaming.gif",
        disc : "Social Gaming is a basic game engine that enables sandbox " +
            "styled game settings to be created as a way for users to create " +
            "creative party games.",
        demo:"https://gitlab.edwardchu.ca/echu/social-gaming"
    },
    {
        img : "inventory.gif",
        disc : "An Inventory System created in Java with Spring Boot to handle " +
            "server requests and storage of inventory data entered by the client",
        demo:"https://gitlab.edwardchu.ca/echu/inventory-server",
        demo2:"https://gitlab.edwardchu.ca/echu/inventory-client"
    },
    {
        img : "social-gaming.gif",
        disc : "Social Gaming is a basic game engine that enables sandbox " +
            "styled game settings to be created as a way for users to create " +
            "creative party games.",
        demo:"https://gitlab.edwardchu.ca/echu/social-gaming"
    },
    {
        img : "inventory.gif",
        disc : "An Inventory System created in Java with Spring Boot to handle " +
            "server requests and storage of inventory data entered by the client",
        demo:"https://gitlab.edwardchu.ca/echu/inventory-server",
        demo2:"https://gitlab.edwardchu.ca/echu/inventory-client"
    },
];


var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                centerMode : false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                centerMode : false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode : false
            }
        }
    ]
};
const SliderComp = () => {
    const arrowRef = useRef(null);
    let sliderProject = "";
    sliderProject = data.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
    return (
        <Container>
            <Slider ref={arrowRef} {...settings}>
                {sliderProject}
            </Slider>
            <Buttons>
                <button
                    onClick={() => arrowRef.current.slickPrev()}
                    className='back'><IoIosArrowBack/></button>
                <button
                    onClick={() => arrowRef.current.slickNext()}
                    className='next'><IoIosArrowForward/></button>
            </Buttons>
        </Container>
    )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.100);
    cursor: pointer;
    color: #01be96;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back{
    left: -1rem;
  }
`