import React from "react";
import {DiReact, DiPython, DiCss3, DiNodejsSmall, DiPostgresql} from "react-icons/di";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";
import {AiOutlineHtml5} from "react-icons/ai";
import {TbBrandSocketIo} from "react-icons/tb";
import {SiAmazondynamodb, SiC, SiCloudflare, SiMysql, SiPfsense, SiProxmox, SiSelenium} from "react-icons/si";
import {BiLogoCPlusPlus, BiLogoGitlab, BiLogoJava} from "react-icons/bi";


const Experiences = () => {
  return (
    <Container id="skills">
      <Slide direction="down">
        <h4>
          My <span className="green">Skills</span>
        </h4>
        <h1>What I Can Do</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={DiReact}
            Icona={DiNodejsSmall}
            Iconc={SiSelenium}
            title={"Full Stack Web Developer"}
            disc={`Incorporated React to making websites 
            appear more fluid and dynamic.
            Utilized nodeJS & SocketIO for backend web hosting and handling real-time
            applications.  Tested websites with Selenium.`}
          />
        </Slide>
        <Slide direction="up">
          <Card
              Icon={BiLogoCPlusPlus}
              Iconb={DiPython}
              Iconc={BiLogoJava}
            title={"Software Developer"}
            disc={`Developed polymorphic C & C++ applications that use
            algorithms for compression and sandbox configurations.
            Created automation tasks with Python and conceptual server REST
            operations in Java with Spring Boot.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={SiMysql}
            Icona={SiAmazondynamodb}
            Iconb={DiPostgresql}
            title={"Database Programmer"}
            disc={`Experienced in writing MySQL, Postgres, and DynamoDB
             table queries for storing persistent data used by web
             applications and server systems.`}
          />

        </Slide>
        <Slide direction="left">
          <Card
              Icon={SiPfsense}
              Icona={SiProxmox}
              Iconb={SiCloudflare}
              title={"System Configurator"}
              disc={`Implemented custom network routing in pFsense and added
              Cloudflare proxy to protect website uptime.  Configured Proxmox
              for virtualization and system sharing.`}
          />

        </Slide>
      </Cards>
    </Container>
  );
};

export default Experiences;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
